<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('expenses_income_transaction.expenses_income_transaction') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>
      <div class="row">
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-12 mb-5">
                <label>{{ $t('expenses_income_transaction.name') }}<span class="text-danger">*</span></label>
                <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.class') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <div class="form-check col-2 m-3">
                    <input class="form-check-input" v-model="data.class" type="radio" name="class" id="class1" value="1" :checked="data.class == 1">
                    <label class="form-check-label" for="class1">
                      {{ $t('expenses_income_transaction.doc_in') }}
                    </label>
                  </div>
                  <div class="form-check col-2  m-3">
                    <input class="form-check-input" v-model="data.class" type="radio" name="class" id="class2" value="2" :checked="data.class == 2">
                    <label class="form-check-label" for="class2">
                      {{ $t('expenses_income_transaction.doc_out') }}
                    </label>
                  </div>
                  <span v-if="validation && validation.class" class="fv-plugins-message-container invalid-feedback">{{ validation.class[0] }}</span>
                </div>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.doc_type') }}<span class="text-danger">*</span></label>
                <select name="" id="status" v-model="data.doc_type" @input="changeTypeObj" class="custom-select" :class="validation && validation.doc_type ? 'is-invalid' : ''">
                  <option v-for="row in doc_type_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.doc_type" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.doc_type[0] }}
                            </span>
              </div>
              <template v-if="data.doc_type == 'Customer'">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('expenses_income_transaction.customer') }}<span class="text-danger">*</span></label>
                  <div class="form-group row">
                    <div class="col-sm-8">
                      <multiselect
                          id="customer_id"
                          v-model="doc_type_obj"
                          :placeholder="$t('expenses_income_transaction.customer')"
                          label="fullname"
                          track-by="id"
                          :options="customers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getCustomers($event)">
                      </multiselect>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('expenses_income_transaction.customer') }}.</span>
                      <span v-if="validation && validation.doc_type_id" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation.doc_type_id[0] }}
                                            </span>
                    </div>
                    <div class="col-sm-4">
                      <button class="btn btn-primary" @click="showModalCustomer">{{ $t('add_new') }}</button>
                    </div>

                  </div>
                </div>
              </template>
              <template v-if="data.doc_type == 'Supplier'">
                <div class="col-lg-12 mb-5">
                  <label>{{ $t('expenses_income_transaction.supplier') }}<span class="text-danger">*</span></label>
                  <div class="form-group row">
                    <div class="col-sm-8">
                      <multiselect
                          v-model="doc_type_obj"
                          :placeholder="$t('expenses_income_transaction.supplier')"
                          label="name"
                          track-by="id"
                          :options="suppliers"
                          :multiple="false"
                          :taggable="false"
                          :show-labels="false"
                          :show-no-options="false"
                          :show-no-results="false"
                          @search-change="getSuppliers($event)">
                      </multiselect>
                      <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('expenses_income_transaction.supplier') }}.</span>
                      <span v-if="validation && validation.doc_type_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.doc_type_id[0] }}
                                    </span>
                    </div>
                    <div class="col-sm-4">
                      <button class="btn btn-primary" @click="showModalSupplier">{{ $t('add_new') }}</button>
                    </div>
                  </div>

                </div>
              </template>
              <template v-if="doc_type_label == 'Employee'">
                <div class="form-group col-md-12 mt-2 mb-2">
                  <label for="employee_id">{{ $t('expenses_income_transaction.employee') }}</label>
                  <div class="input-group">
                    <multiselect v-model="doc_type_obj"
                                 :placeholder="$t('expenses_income_transaction.employee')"
                                 label="full_name"
                                 track-by="id"
                                 :options="employees"
                                 :multiple="false"
                                 :taggable="false"
                                 :show-labels="false"
                                 :show-no-options="false"
                                 :show-no-results="false"
                                 @search-change="getEmployees($event)">
                    </multiselect>
                    <div class="input-group-prepend">
                      <a class="btn btn-primary" href="/employees/employees/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                    </div>
                  </div>
                  <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('expenses_income_transaction.employee') }}.</span>

                </div>
              </template>
            </div>
          </div>


          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.amount') }}<span class="text-danger">*</span></label>
                <input type="number" v-model="data.amount" @input="getTotalTax" class="form-control" :class="validation && validation.amount ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.amount" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.amount[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.currency') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <select name="" id="currency_id" v-model="data.currency_id" @input="getTotalTax" class="custom-select" :class="validation && validation.currency_id ? 'is-invalid' : ''">
                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/currencies" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.currency_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.currency_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('exchange_rate') }}</label>
                <input type="number" v-model="data.exchange_rate" @input="getTotalTax" step="0.01" min="0" class="form-control" :class="validation && validation.exchange_rate ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.exchange_rate" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.exchange_rate[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.payment_method') }}<span class="text-danger">*</span></label>
                <select name="" id="payment_method" v-model="data.payment_method" class="custom-select" :class="validation && validation.payment_method ? 'is-invalid' : ''">
                  <option v-for="row in payment_methods" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.payment_method" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.payment_method[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5" v-if="payment_method_type == 'bank'">
                <label>{{ $t('expenses_income_transaction.bank') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="bank"
                               :class="validation && validation.method_type_id ? 'is-invalid' : ''"
                               :placeholder="$t('expenses.bank')"
                               label="name"
                               track-by="id"
                               :options="banks"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="useBank"
                               @search-change="getBanks($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/finances/banks/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.banks') }}.</span>
                <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.type_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5" v-else-if="payment_method_type == 'treasury'">
                <label>{{ $t('expenses_income_transaction.treasury') }}<span class="text-danger">*</span></label>
                <div class="input-group">
                  <multiselect v-model="treasury"
                               :class="validation && validation.type_id ? 'is-invalid' : ''"
                               :placeholder="$t('expenses.treasury')"
                               label="name"
                               track-by="id"
                               :options="treasuries"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false"
                               @input="useTreasury">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/finances/treasuries/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('payment_sales_invoices.treasuries') }}.</span>
                <span v-if="validation && validation.type_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.type_id[0] }}
                            </span>
              </div>
            </div>
          </div>


        </div>
        <div class="col-md-6">
          <div class="card card-custom">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.code') }}</label>
                <input type="text" v-model="data.code" class="form-control" :class="validation && validation.code ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.code" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.code[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.date') }}</label>
                <input type="date" v-model="data.expenses_date" class="form-control" :class="validation && validation.expenses_date ? 'is-invalid' : ''"/>
                <span v-if="validation && validation.expenses_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.expenses_date[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('expenses_income_transaction.inventory') }}</label>
                <div class="input-group">
                  <multiselect v-model="inventory"
                               :placeholder="$t('sales_refund.inventory')"
                               label="name"
                               track-by="id"
                               :options="inventories"
                               :multiple="false"
                               :class="validation && validation.inventory_id ? 'is-invalid' : ''"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/inventories/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.inventory_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.inventory_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('branch') }}</label>
                <div class="input-group">
                  <multiselect v-model="branch"
                               :placeholder="$t('branch')"
                               label="name"
                               track-by="id"
                               :options="branches"
                               :multiple="false"
                               :taggable="false"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/settings/branches/create" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span v-if="validation && validation.branch_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.branch_id[0] }}
                            </span>
              </div>
              <div class="col-lg-6 mb-5">
                <label>{{ $t('status') }}</label>
                <select name="" id="status" :disabled="true" v-model="data.status" class="custom-select" :class="validation && validation.status ? 'is-invalid' : ''">
                  <option v-for="row in status_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                </select>
                <span v-if="validation && validation.status" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.status[0] }}
                            </span>
              </div>
            </div>
          </div>

          <b-card no-body v-if="doc_type_obj && (doc_type_label == 'Customer' || doc_type_label == 'Supplier')" class="mt-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
              <b-button block v-b-toggle.accordion-50 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                <span v-if="doc_type_label == 'Customer'">{{ $t('customer_data_details')}}</span>
                <span v-else-if="doc_type_label == 'Supplier'">{{ $t('supplier_data_details')}}</span>
<!--                <span v-else-if="data.doc_type == 'Employee'">{{ $t('employee_data_details')}}</span>-->
              </b-button>
            </b-card-header>

            <b-collapse id="accordion-50" accordion="my-accordion" role="tabpanel">
              <b-card-body class="row">
                <div class="col-lg-12 mb-5">
                  <p><b>{{ $t('name')}}: </b>
                    <span v-if="doc_type_label == 'Customer'">{{doc_type_obj.fullname}}</span>
                    <span v-else-if="doc_type_label == 'Supplier'">{{doc_type_obj.name}}</span>
<!--                    <span v-else-if="data.doc_type == 'Employee'">{{doc_type_obj.full_name}}</span>-->
                  </p>
                  <p><b>{{ $t('mobile')}}: </b> {{doc_type_obj.mobile}}</p>
                  <p><b>{{ $t('email')}}: </b> {{doc_type_obj.email}}</p>
                  <p v-if="doc_type_label == 'Customer'"><b>{{ $t('tax_no')}}: </b> {{doc_type_obj.tax_register}}</p>
                  <p v-if="doc_type_label == 'Supplier'"><b>{{ $t('tax_no')}}: </b> {{doc_type_obj.taxno}}</p>
                </div>
                <div class="col-md-12 mb-5" v-if="doc_type_label == 'Customer' || doc_type_label == 'Supplier'">
                  <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                    <h6 class="my-auto text-white">{{ $t('balances') }}</h6>
                  </div>
                  <table class="table table-row-bordered">
                    <thead>
                    <tr>
                      <th>{{ $t('customers.balance') }}</th>
                      <th>{{ $t('customers.debit') }}</th>
                      <th>{{ $t('customers.currency') }}</th>
                      <th>{{ $t('customers.last_validated_date') }}</th>
                      <th></th>
                    </tr>

                    </thead>
                    <tbody>
                    <template v-if="doc_type_obj.balances && doc_type_obj.balances.length > 0">
                      <tr v-for="(row, index) in doc_type_obj.balances" :key="index">
                        <td>{{row.credit}}</td>
                        <td>{{row.debit}}</td>
                        <td>{{row.currency_name}}</td>
                        <td>{{ row.last_validated_date }}</td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="4">{{ $t('no_balances') }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>

          <div class="card card-custom mt-5">
            <div class="card-body row">
              <div class="col-lg-6 mb-5">
                <label>{{ $t('accounts') }}</label>
                <div class="input-group">
                  <multiselect
                      v-model="account"
                      :placeholder="$t('accounts')"
                      label="label"
                      track-by="id"
                      :options="accounts"
                      :multiple="false"
                      :taggable="false"
                      :show-labels="false"
                      :show-no-options="false"
                      :show-no-results="false"
                      @search-change="getAccounts($event)">
                  </multiselect>
                  <div class="input-group-prepend">
                    <a class="btn btn-primary" href="/accounting/chart-accounts" target="_blank"><i class="fa fa-plus" style="padding: 0"></i></a>
                  </div>
                </div>
                <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('accounts') }}.</span>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="mt-5">
        <b-tabs content-class="mt-3" class="nav-custom-link">
          <b-tab :title="$t('expenses_income_transaction.taxes')" v-if="is_company_has_tax_no">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-12 mb-5">
                  <b-form-checkbox size="lg" v-model="data.is_included_tax" :disabled="true" name="check-button" switch>{{ $t('expenses.is_included_tax') }}</b-form-checkbox>
                </div>

                <div class="col-lg-3 mb-5">
                  <label>{{ $t('expenses_income_transaction.tax_type') }}</label>
                  <!-- <select name="" id="tex_id" v-model="data.tax_id" class="custom-select" :class="validation && validation.tax_id ? 'is-invalid' : ''">
                      <option v-for="row in taxes" :value="row.id" :key="row.id">{{ row.name }}</option>
                  </select> -->
                  <multiselect v-model="tax"
                               :placeholder="$t('expenses.tax_type')"
                               :class="validation && validation.tax_id ? 'is-invalid' : ''"
                               label="name" track-by="id"
                               :options="taxes"
                               :multiple="false"
                               :taggable="true"
                               @input="selectTax"
                               :show-labels="false"
                               :show-no-options="false"
                               :show-no-results="false">
                  </multiselect>
                  <span v-if="validation && validation.tax_id" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.tax_id[0] }}
                            </span>
                </div>
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('expenses_income_transaction.tax_val') }}</label>
                  <input type="number" :disabled="true" v-model="data.tax_val" class="form-control" :class="validation && validation.tax_val ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.tax_val" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.tax_val[0] }}
                            </span>
                </div>
                <div class="col-lg-3 mb-5">
                  <label>{{ $t('expenses_income_transaction.total_tax') }}</label>
                  <input type="number" :disabled="true" v-model="data.total_tax" class="form-control" :class="validation && validation.total_tax ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.total_tax" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.total_tax[0] }}
                            </span>
                </div>
                <div class="col-lg-3 mb-5 pt-10">
                  <b-form-checkbox size="lg" v-model="data.is_recurring" name="check-button" switch>{{ $t('expenses.is_recurring') }}</b-form-checkbox>
                </div>
                <div class="col-lg-3 mb-5" v-show="data.is_recurring">
                  <label>{{ $t('expenses_income_transaction.frequency') }}</label>
                  <select name="" id="frecuency" v-model="data.frecuency" class="custom-select" :class="validation && validation.frecuency ? 'is-invalid' : ''">
                    <option v-for="row in frequencies" :value="row.id" :key="row.id">{{ row.title }}</option>
                  </select>
                  <span v-if="validation && validation.frecuency" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.frecuency[0] }}
                            </span>
                </div>
                <div class="col-lg-3 mb-5" v-show="data.is_recurring">
                  <label>{{ $t('expenses_income_transaction.end_date') }}</label>
                  <input type="date" v-model="data.recurring_date" class="form-control" :class="validation && validation.recurring_date ? 'is-invalid' : ''"/>
                  <span v-if="validation && validation.recurring_date" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.recurring_date[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('notes')">
            <div class="card card-custom">
              <div class="card-body row">
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('expenses.attachment') }}</label>
                  <div class="form-group">
                    <upload-file
                        @file="listenerAttachment"
                        :inner-id="'attachment'"
                        :placeholder="$t('items.upload_image')"
                        :upload="dir_upload"
                        :start-link="'base'"
                        v-bind:valueprop="{name:'attachment',path:attachment_url}" v-if="reloadUploadAttachment">
                    </upload-file>
                  </div>
                </div>
                <div class="col-lg-6 mb-5">
                  <label>{{ $t('expenses.notes') }}</label>
                  <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                  <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.notes[0] }}
                            </span>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>


      </div>
      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>
    <!--end::customer-->

    <b-modal ref="modal-customer" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModalCustomer()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>
    <b-modal ref="modal-supplier" size="lg" hide-footer :title="$t('suppliers.add_new_supplier')">
      <supplier-form @hide-modal="hideModalSupplier()" @handling-data="getDataAfterCreateNewSupplier"></supplier-form>
    </b-modal>

  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import {mapGetters, mapState} from "vuex";
import SupplierForm from "@/view/content/forms/SupplierForm";
import CustomerForm from "@/view/content/forms/CustomerForm";

export default {
  name: "form-expenses-income-transactions",
  components: {'supplier-form': SupplierForm, 'customer-form': CustomerForm,},
  data() {
    return {
      mainRoute: 'finances/expenses_incomes',
      mainRouteDependency: 'base/dependency',
      dir_upload: 'expenses',

      data: {
        name: null,
        code: null,
        amount: null,
        currency_id: null,
        tax_id: null,
        tax_val: null,
        total_tax: null,
        expenses_date: null,
        recurring_date: null,
        frecuency: null,
        attachment: null,
        notes: null,
        status: 1,
        is_recurring: false,
        inventory_id: null,
        branch_id: null,
        account_id: null,
        exchange_rate: null,
        payment_method: null,
        type_id: null,
        type_type: null,
        doc_type: null,
        doc_type_id: null,
        class: null,
        is_included_tax: false,
      },
      doc_type_label: null,
      idEditing: this.$route.params.id,
      isEditing: false,
      attachment_url: null,
      reloadUploadAttachment: true,

      tax: [],
      taxes: [],
      accounts: [],
      account: null,
      currencies: [],
      status_list: [],
      frequencies: [],
      inventories: [],
      branches: [],
      branch: null,
      inventory: null,
      validation: null,
      payment_methods: [],
      treasuries: [],
      treasury: null,
      bank: null,
      banks: [],
      doc_type_list: [],
      doc_type_obj: null,
      suppliers: [],
      customers: [],
      employees: [],
      payment_method_type: null,
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters({currentUserPersonalInfo: "currentUserPersonalInfo", company: "getCompanyData"}),
    is_company_has_tax_no(){
      return this.company.tax_no ? true : false;
    }

  },
  watch: {
    'data.payment_method': function (val) {
      this.payment_method_type = null;
      if (val){
        let _method = this.payment_methods.find(row => row.id == val);
        if (_method){
          this.payment_method_type = _method.cash_type;
        }
      }
    },
    account: function (val) {
      if (val) {
        this.data.account_id = val.id;
      }
    },
    // 'data.name': function (val) {
    //     if (val){
    //         this.data.name = val.replace(/[\d]/g, "");
    //     }
    // },
    // tax: function (val) {
    //   if (val) {
    //     this.data.tax_id = val.id;
    //     this.data.tax_val = val.value_rate;
    //   } else {
    //     this.data.tax_id = null;
    //     this.data.tax_val = null;
    //     this.data.total_tax = null;
    //   }
    // },
    inventory: function (val) {
      if (val) {
        this.data.inventory_id = val.id;
      } else {
        this.data.inventory_id = null;
      }
    },
    branch: function (val) {
      if (val) {
        this.data.branch_id = val.id;
      } else {
        this.data.branch_id = null;
      }
    },

    'data.is_recurring': function (val) {
      if (val == 0) {
        this.data.frecuency = '';
        this.data.recurring_date = '';
      }
    },
    "data.currency_id": function (val) {
      if (val) {
        this.getExchangeRate(val);
      } else {
        this.data.exchange_rate = null;
      }
    },
    // treasury: function (val) {
    //   if (val && val.id) {
    //     this.data.type_id = val.id;
    //     this.data.type_type = 'Treasuries';
    //   }else {
    //     this.data.type_id = null;
    //   }
    // },
    // bank: function (val) {
    //   if (val && val.id) {
    //     this.data.type_id = val.id;
    //     this.data.type_type = 'Bank';
    //   }else {
    //     this.data.type_id = null;
    //   }
    // },

    doc_type_obj: function (val) {
      if (val && val.id)
        this.data.doc_type_id = val.id;
      else
        this.data.doc_type_id = null;
    },
  },

  methods: {
    docTypeListInit(){
      this.doc_type_list = [];
      if(this.$can('customers.list')){
        this.doc_type_list.push({id: 'Customer', title: this.$t('expenses_income_transaction.customer')})
      }
      if(this.$can('suppliers.list')){
        this.doc_type_list.push({id: 'Supplier', title: this.$t('expenses_income_transaction.supplier')})
      }
      if(this.$can('employees.list')){
        this.doc_type_list.push({id: 'Employee', title: this.$t('expenses_income_transaction.employee')})
      }

      this.doc_type_list.push({id: 'Others', title: this.$t('expenses_income_transaction.others')})


    },
    useBank(obj) {
      this.data.type_id = obj.id;
      this.data.type_type = 'Bank';

    },
    useTreasury(obj) {
      this.data.type_id = obj.id;
      this.data.type_type = 'Treasuries';

    },
    selectTax(val) {
      if (val) {
        this.data.tax_id = val.id;
        this.data.tax_val = val.value_rate;
        this.getTotalTax();
      } else {
        this.data.tax_id = null;
        this.data.tax_val = null;
        this.data.total_tax = null;
      }
    },
    changeTypeObj: _.debounce(function (event) {
      this.doc_type_label = (event && event.target.value) ? event.target.value : null;
      this.doc_type_obj = null;
      this.data.doc_type_id = null;
    }, 100),
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      this.data.is_recurring = this.data.is_recurring ? 1 : 0;
      ApiService.post(`${this.mainRoute}`, {
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);
            this.$router.push({name: 'expenses-income-transaction.index'});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      this.data.is_recurring = this.data.is_recurring ? 1 : 0;
      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        ...this.data,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'expenses-income-transaction.index'});
        this.$successAlert(response.data.message);
      })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      this.reloadUploadAttachment = false;
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.code = response.data.data.code;
        this.data.amount = response.data.data.amount;
        this.data.currency_id = response.data.data.currency_id;
        this.data.tax_id = response.data.data.tax_id;
        this.tax = response.data.data.tax;
        this.data.tax_val = response.data.data.tax_val;
        this.data.total_tax = response.data.data.total_tax;
        this.data.expenses_date = response.data.data.expenses_date;
        this.data.recurring_date = response.data.data.recurring_date;
        this.data.frecuency = response.data.data.frecuency;
        this.data.attachment = response.data.data.attachment;
        this.data.notes = response.data.data.notes;
        this.data.status = response.data.data.status;
        this.data.is_recurring = response.data.data.is_recurring;
        this.attachment_url = response.data.data.attachment_url;
        this.data.exchange_rate = response.data.data.exchange_rate;
        this.data.payment_method = response.data.data.payment_method;
        this.data.is_included_tax = response.data.data.is_included_tax;

        this.treasury = response.data.data.treasury;
        this.data.account_id = response.data.data.account_id;
        this.account = response.data.data.account;
        this.branch = response.data.data.branch;
        this.inventory = response.data.data.inventory;

        this.data.type_id = response.data.data.type_id;
        this.data.type_type = response.data.data.type_type_label;
        this.data.class = response.data.data.class;
        this.bank = response.data.data.bank;
        this.data.doc_type = response.data.data.doc_type_label;
        this.doc_type_label = response.data.data.doc_type_label;
        this.doc_type_obj = response.data.data.doc_type_obj;

        if (!response.data.data.account_id) {
          this.getDefaultAccount();
        }
        this.reloadUploadAttachment = true;
      });
    },

    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },
    getTaxes() {
      ApiService.get(this.mainRouteDependency + "/taxes").then((response) => {
        this.taxes = response.data.data;
      });
    },
    getDefaultTax() {
      ApiService.get(`${this.mainRouteDependency}/default-tax`).then((response) => {
        this.tax = response.data.data;
      });
    },


    getStatusList() {
      ApiService.get(this.mainRouteDependency + "/exp_status").then((response) => {
        this.status_list = response.data.data;
      });
    },
    getFrequencies() {
      ApiService.get(this.mainRouteDependency + "/frecuency_list").then((response) => {
        this.frequencies = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + "/inventories").then((response) => {
        this.inventories = response.data.data;
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    getExchangeRate(id) {
      ApiService.get(`${this.mainRouteDependency}/exchange_rate/` + id).then((response) => {
        this.data.exchange_rate = response.data.data.initial_val ? response.data.data.initial_val : 1;
      });
    },

    listenerAttachment(event) {
      if (event) {
        this.attachment_url = event.pathDB;
        this.data.attachment = event.name;
      }
    },
    getCode() {
      ApiService.get(this.mainRouteDependency + "/code-setting/17").then((response) => {
        this.data.code = response.data.data.code;
      });
    },
    // getAccounts() {
    //     ApiService.get(this.mainRouteDependency + `/account_parents`,{params:{checkDisabled: 1,key:'expenses'}}).then((response) => {
    //         this.accounts = response.data.data;
    //     });
    // },
    getAccounts(filter) {
      if (filter && filter.length >= 3)
        return ApiService.get(this.mainRouteDependency + `/account_parents_leaf_nodes`, {params: {name: filter}}).then((response) => {
          this.accounts = response.data.data;
        });
    },
    getDefaultAccount() {
      ApiService.get(`${this.mainRouteDependency}/get_default_account/expenses`).then((response) => {
        // this.data.account_id = response.data.data.id;
        this.account = response.data.data;
      });
    },
    updateValueAccount(value) {
      this.data.account_id = value;
    },
    async getPaymentMethods() {
      await ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_methods = response.data.data;
      });
    },
    getTreasuries() {
      ApiService.get(`${this.mainRouteDependency}/treasuries`).then((response) => {
        this.treasuries = response.data.data;
      });
    },
    getTotalTax: _.debounce(function () {
      this.data.exchange_rate = this.data.exchange_rate ? this.data.exchange_rate : 1;
      if (this.is_company_has_tax_no && this.data.tax_id && this.data.amount && this.data.currency_id && this.data.exchange_rate)
      ApiService.get(`/v2/items/taxes/${this.data.tax_id}/amount?`,{ params: {
          amount:this.data.amount,
          currency:this.data.currency_id,
          exchange_rate:this.data.exchange_rate,
        }}).then((response) => {
        this.data.is_included_tax = (response.data.data.included_taxes_total == 0 ? false : true);
        this.data.total_tax = response.data.data.tax_total ? response.data.data.tax_total : 0;
      });

    }, 120),
    getBanks(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/banks`, {params: {filter: filter}}).then((response) => {
          this.banks = response.data.data;
        });
      } else {
        this.banks = [];
      }
    },
    async getSuppliers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },

    async getCustomers(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/customers`, {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
      }
    },
    async getEmployees(filter) {
      if (filter && filter.length >= 3) {
        await ApiService.get(`${this.mainRouteDependency}/employees`, {params: {filter: filter}}).then((response) => {
          this.employees = response.data.data;
        });
      }
    },

    showModalCustomer() {
      this.$refs['modal-customer'].show()
    },
    hideModalCustomer() {
      this.$refs['modal-customer'].hide();
    },
    getDataAfterCreateNewCustomer(object) {
      this.doc_type_obj = object;
      this.data.doc_type_id = object.id;
    },

    showModalSupplier() {
      this.$refs['modal-supplier'].show()
    },
    hideModalSupplier() {
      this.$refs['modal-supplier'].hide();
    },
    getDataAfterCreateNewSupplier(object) {
      this.doc_type_obj = object;
      this.data.doc_type_id = object.id;
    },


    defaultDataForUser() {
      ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.data.currency_id = response.data.data.currency_id;

        // this.data.inventory_id = response.data.data.inventory_id;
        // this.inventory = response.data.data.inventory;

        // this.tax = response.data.data.tax;
        // this.data.tax_id = response.data.data.tax_id;
        // this.data.tax_val = response.data.data.value_rate;

        this.data.branch_id = response.data.data.branch_id;
        this.branch = response.data.data.branch;
      });
    }
  },

  mounted() {
    if (!this.data.expenses_date) {
      this.data.expenses_date = new Date().toISOString().substr(0, 10);
    }
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.expenses_income_transaction"), route: '/finances/expenses-income-transaction'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    let promise = this.getPaymentMethods();
    this.docTypeListInit();
    this.getCurrencies();
    this.getTaxes();
    this.getInventories();
    // this.getDefaultTax();

    this.getStatusList();
    this.getFrequencies();
    this.getBranches();
    this.getAccounts();
    if (!this.data.account_id) {
      this.getDefaultAccount();
    }

    this.getTreasuries();

    if (this.idEditing) {
      Promise.all([promise]).then(()=>{
        this.getData();
      })

    } else {
      this.data.recurring_date = new Date().toISOString().substr(0, 10);
      this.getCode();
      this.defaultDataForUser();
    }

  },
};
</script>
